import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { animated } from 'react-spring';
import _ from 'lodash';

const A = {
  Img: animated(Img),
};

const Image = ({ data, style, imgStyle, ...props }) => {
  const { alt = '', childImageSharp, image } = data;

  let fluidObject = _.get(childImageSharp, 'fluid');
  fluidObject = _.get(image, 'childImageSharp.fluid', fluidObject);

  let fixedObject = _.get(childImageSharp, 'fixed');
  fixedObject = _.get(image, 'childImageSharp.fixed', fixedObject);

  if (fluidObject) {
    return <A.Img fluid={fluidObject} alt={alt} style={style} imgStyle={imgStyle} {...props} />;
  }

  if (fixedObject) {
    return <A.Img fixed={fixedObject} alt={alt} style={style} imgStyle={imgStyle} {...props} />;
  }

  if (image && typeof image === 'string') {
    const styles = Object.assign({}, style, imgStyle);
    return <animated.img src={image} alt={alt} style={styles} {...props} />;
  }

  return null;
};

Image.propTypes = {
  data: PropTypes.shape(
    PropTypes.shape({
      alt: PropTypes.string,
      childImageSharp: PropTypes.object,
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    }),
  ).isRequired,
  ...Img.propTypes,
};

Image.defaultProps = {
  ...Img.defaultProps,
};

export default Image;
