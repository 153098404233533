import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import * as THREE from 'three';
import _ from 'lodash';

import { useMousePosition } from 'src/modules/mouse';
import { useTransitionAnimation } from 'src/modules/animations';
import { vertex, fragment, createColor, createFloat, createRandomFloat, createBoolean } from 'src/modules/3d';

let start;
let renderer;
let camera;
let scene;
let blob;
let mouse;
let animationFrameRequest;

function createGeometry(radius, detail) {
  const geometry = new THREE.IcosahedronBufferGeometry(radius, detail);
  return geometry;
}

// 921600 1.9 (720p)
// 1440000 2.4 (900p)
// 2073600 2.8 (1080p)
// 3686400 3.8 (1440p)

// 921600 2.1 (720p)
// 1440000 2.6 (900p)
// 2073600 3.0 (1080p)
// 3686400 4.0 (1440p)

// 921600 2.2 (720p)
// 1440000 2.7 (900p)
// 2073600 3.1 (1080p)
// 3686400 4.1 (1440p)

function findPointscale(x) {
  //  const y = 291.2238 + (0.4077526 - 291.2238) / (1 + Math.pow(x / 6880721000, 0.5896298));
  // const y = 291.4238 + (0.6077526 - 291.4238) / (1 + Math.pow(x / 6880721000, 0.5896298));
  const y = 291.5238 + (0.7077526 - 291.5238) / (1 + Math.pow(x / 6880721000, 0.5896298));
  return y;
}

function createMaterial() {
  const screenWidth = _.get(window, 'innerWidth', 2560);
  const screenHeight = _.get(window, 'innerHeight', 2560);
  const pixelRatio = _.get(window, 'devicePixelRatio', 1);
  const pointscale = findPointscale(screenWidth * screenHeight) * pixelRatio;

  console.log(
    `w: ${screenWidth} h: ${screenHeight} e: ${screenWidth * screenHeight} pr: ${pixelRatio} pointscale: ${pointscale}`,
  );

  const uniforms = {
    time: createFloat(0.0),
    pointscale: createFloat(pointscale),
    decay: createRandomFloat(0.04, 0.1),
    complex: createFloat(0.2),
    waves: createRandomFloat(10.0, 10.0),
    eqcolor: createFloat(11.0),
    redhell: createBoolean(true),
    color: createColor(0x000000),
    alpha: createFloat(1.0),
  };

  const material = new THREE.ShaderMaterial({
    uniforms,
    vertexShader: vertex.turbulence,
    fragmentShader: fragment.color,
  });

  return material;
}

function init(width, height, parent) {
  mouse = new THREE.Vector2();
  start = Date.now();

  renderer = new THREE.WebGLRenderer({ powerPreference: 'high-performance' });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(width, height);
  parent.appendChild(renderer.domElement);

  camera = new THREE.PerspectiveCamera(70, width / height, 1, 1000);
  camera.position.z = 12;

  scene = new THREE.Scene();
  scene.background = new THREE.Color(0xffffff);

  renderer.render(scene, camera);
}

function createBlob() {
  blob = {};

  blob.object = new THREE.Object3D();
  blob.geometry = createGeometry(2, 60);
  blob.material = createMaterial();
  blob.mesh = new THREE.Points(blob.geometry, blob.material);
  blob.object.add(blob.mesh);

  scene.add(blob.object);
}

function clear(canvasWrapper) {
  if (animationFrameRequest) {
    cancelAnimationFrame(animationFrameRequest);
  }
  if (canvasWrapper) {
    canvasWrapper.innerHTML = '';
  }
  if (blob) {
    blob.object.remove(...blob.object.children);
  }

  mouse = null;
  start = null;
  renderer = null;
  camera = null;
  scene = null;
  blob = null;
}

function animate() {
  animationFrameRequest = requestAnimationFrame(animate);

  if (!start || !blob || !mouse || !renderer || !scene || !camera) return;

  blob.material.uniforms.time.value = 0.0001 * (Date.now() - start);
  blob.object.rotation.x += mouse.x * Math.abs(20 * 0.0001);
  blob.object.rotation.y += mouse.y * Math.abs(20 * 0.0001);

  renderer.render(scene, camera);
}

const transitionAnimations = [
  {
    status: ['entering', 'entered', 'POP'],
    styles: {
      delay: 100,
      opacity: 1,
    },
  },
  {
    status: ['exiting', 'exited'],
    styles: {
      opacity: 0,
    },
  },
];

const S = {
  Canvas: styled.div`
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  `,
};

const A = {
  Canvas: animated(S.Canvas),
};

function initializeAll(canvasRef) {
  init(window.innerWidth, window.innerHeight, canvasRef.current);
  createBlob();
  animate();
}

const Background3D = ({ transitionStatus }) => {
  const canvasRef = useRef(null);
  const [status, set] = useState('');

  const animationStyles = useSpring(
    useTransitionAnimation(
      {
        from: { opacity: 0 },
        config: { duration: ['entering', 'POP'].includes(status || transitionStatus) ? 1000 : 500 },
      },
      transitionAnimations,
      status || transitionStatus,
    ),
  );

  useMousePosition((x, y) => {
    if (!mouse) return;
    mouse.x = (x / window.innerWidth) * 2 - 1;
    mouse.y = -(y / window.innerHeight) * 2 + 1;
  });

  useEffect(() => {
    initializeAll(canvasRef);
    const reInitializeAll = _.debounce(() => {
      clear(canvasRef.current);
      initializeAll(canvasRef);
      set('');
    }, 500);

    window.onresize = () => {
      set('exited');
      reInitializeAll();
    };

    return () => {
      clear(canvasRef.current);
      window.onresize = null;
    };
  }, []);

  return <A.Canvas id="canvas-wrapper" style={animationStyles} ref={canvasRef} />;
};

Background3D.propTypes = {
  transitionStatus: PropTypes.string.isRequired,
};

export default Background3D;
