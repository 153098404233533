import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { colors } from 'src/modules/styles';
import { useTransitionAnimation } from 'src/modules/animations';
import Container from './layout.Container';

const transitionAnimations = [
  {
    status: ['entering', 'entered', 'POP'],
    styles: {
      delay: 300,
      opacity: 1,
      transform: 'translate3d(0,0px,0)',
    },
  },
  {
    status: ['exiting'],
    styles: {
      opacity: 0,
      transform: 'translate3d(0,-50px,0)',
    },
  },
  {
    status: ['exited'],
    styles: {
      delay: 200,
      opacity: 0,
      transform: 'translate3d(0,50px,0)',
    },
  },
];

const transitionAnimationsSwipe = [
  {
    status: ['entering', 'entered', 'POP'],
    styles: {
      opacity: 1,
      transform: 'translate3d(0,0%,0)',
    },
  },
  {
    status: ['exiting', 'exited'],
    styles: {
      opacity: 1,
      transform: 'translate3d(0,-110%,0)',
    },
  },
];

const S = {
  Container: styled(animated(Container))`
    display: flex;
    flex: 1;
    overflow-x: hidden;
    background-color: ${colors.white};
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-height: 100%;
  `,
};

const from = {
  from: {
    opacity: 0,
    transform: 'translate3d(0, 50px, 0)',
  },
};

const fromSwipe = {
  from: {
    opacity: 1,
    transform: 'translate3d(0, 110%, 0)',
  },
};

const PageContainer = forwardRef(
  (
    {
      transitionStatus,
      swipe,
      transitionAnimations: propTransitionAnimations,
      from: propFrom,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const animationStyles = useSpring(
      useTransitionAnimation(propFrom || from, propTransitionAnimations || transitionAnimations, transitionStatus),
    );

    const animationSwipeStyles = useSpring(
      useTransitionAnimation(
        propFrom || fromSwipe,
        propTransitionAnimations || transitionAnimationsSwipe,
        transitionStatus,
      ),
    );
    return (
      <S.Container
        ref={ref}
        notCenter
        className={className}
        style={swipe ? animationSwipeStyles : animationStyles}
        {...props}
      >
        {children}
      </S.Container>
    );
  },
);

PageContainer.propTypes = {
  transitionStatus: PropTypes.string.isRequired,
  swipe: PropTypes.bool,
  transitionAnimations: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.string).isRequired,
      styles: PropTypes.object.isRequired,
    }),
  ),
  from: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

PageContainer.defaultProps = {
  swipe: false,
  transitionAnimations: null,
  from: null,
  className: null,
  children: null,
};

export default PageContainer;
