import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { animated, useSpring, useSprings } from 'react-spring';
import { BrowserView } from 'react-device-detect';
import _ from 'lodash';

import { slugify } from 'src/modules/seo';
import { interpolate } from 'src/modules/animations';
import { inBounds, distanceFromCenter, useMousePosition } from 'src/modules/mouse';
import { Image } from 'src/modules/image';
import { padding, breakpoints } from 'src/modules/styles';
import PageContainer from './layout.PageContainer';
import MenuItem from './layout.MenuItem';

const MAX_IMAGE_SIZE = 500;

const S = {
  PageContainer: styled(PageContainer)`
    height: 100%;
  `,
  Wrapper: styled.div`
    width: auto;
    overflow-x: hidden;
    ${({ centered }) =>
      centered
        ? css`
            margin: auto;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          `
        : css`
            margin-top: 150px;
          `}

    ${breakpoints.s`
        width: 100%;
    `}
  `,
  Page: styled.div`
    overflow-y: auto;
    width: auto;
    min-height: 100%;
    ${padding.horizontal.s}

    ${props =>
      props.noItem &&
      css`
        display: flex;
        align-items: center;
      `}

    ${breakpoints.xs`
      padding-left: 0;
      padding-right: 0;

          ${props =>
            props.noItem &&
            css`
              width: 100%;
            `}

    `}
  `,

  ImageWrapper: styled.div`
    pointer-events: none;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -${MAX_IMAGE_SIZE / 2}px;
    margin-left: -${MAX_IMAGE_SIZE / 2}px;
    width: ${MAX_IMAGE_SIZE}px;
  `,
  Image: styled(Image)`
    object-fit: contain;
    margin: auto;
    width: ${MAX_IMAGE_SIZE}px;
    height: ${MAX_IMAGE_SIZE}px;
  `,
};

const A = {
  ImageWrapper: animated(S.ImageWrapper),
  Image: animated(S.Image),
};

const MenuPage = ({ lang, routeRoot, items: itemsProp, currentItem, transition, children }) => {
  const pageContainerRef = useRef(null);
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);

  const hasCurrentItem = !!currentItem;

  useEffect(() => {
    if (hasCurrentItem) {
      const currentItemSlug = slugify(currentItem.title);
      const filteredItems = itemsProp.filter(item => slugify(item.title) !== currentItemSlug);
      setItems(filteredItems);
      setImages(filteredItems.map(item => item.image));
    } else {
      setItems(itemsProp);
      setImages(itemsProp.map(item => item.image));
    }
  }, [itemsProp, currentItem, hasCurrentItem]);

  const [imageSprings, setImageSprings] = useSprings(images.length, () => ({ opacity: 0 }));
  const hideTooltipImage = () => setImageSprings(() => ({ opacity: 0 }));
  const showTooltipImage = index => setImageSprings(i => ({ opacity: i === index ? 1 : 0 }));

  const [imagePosition, setImagePosition] = useSpring(() => ({ xy: [0, 0] }));
  useMousePosition((x, y) => {
    if (!pageContainerRef.current) return;
    const boundingBox = pageContainerRef.current.getBoundingClientRect();
    const distanceInBounds = inBounds(boundingBox, { width: MAX_IMAGE_SIZE / 2, height: MAX_IMAGE_SIZE / 2 });
    let xy = distanceInBounds(x + MAX_IMAGE_SIZE, y);
    xy = distanceFromCenter(...xy);
    setImagePosition({ xy });
  });

  return (
    <S.PageContainer ref={pageContainerRef} transitionStatus={transition.status} swipe={transition.type === 'swipe'}>
      <BrowserView renderWithFragment>
        {imageSprings.map(({ opacity }, index) =>
          !images[index] ? null : (
            <A.ImageWrapper key={index} style={interpolate.translateXY(imagePosition)}>
              <A.Image
                data={images[index]}
                style={{ opacity: opacity.interpolate(o => o) }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </A.ImageWrapper>
          ),
        )}
      </BrowserView>
      <S.Page noItem={!currentItem}>
        {children}
        <S.Wrapper centered={!hasCurrentItem}>
          {items.map((item, index) => (
            <MenuItem
              key={item.title}
              to={
                lang === 'el' ? `/${routeRoot}/${slugify(item.title)}` : `/${lang}/${routeRoot}/${slugify(item.title)}`
              }
              entry={{
                state: { type: 'swipe' },
              }}
              exit={{
                state: { type: 'swipe' },
              }}
              onMouseEnter={() => {
                showTooltipImage(index);
              }}
              onMouseLeave={() => {
                hideTooltipImage();
              }}
              onClick={() => {
                hideTooltipImage();
              }}
            >
              {item.title}
            </MenuItem>
          ))}
        </S.Wrapper>
      </S.Page>
    </S.PageContainer>
  );
};

MenuPage.propTypes = {
  routeRoot: PropTypes.string.isRequired,
  items: PropTypes.arrayOf({
    title: PropTypes.string.isRequired,
    image: PropTypes.object,
  }).isRequired,
  currentItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object,
  }),
  transition: PropTypes.shape({
    status: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

MenuPage.defaultProps = {
  currentItem: null,
  children: null,
};

export default MenuPage;
