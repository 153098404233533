import * as THREE from 'three';

export const createFloat = value => ({ type: 'f', value });

export const createRandomFloat = (min, max) => createFloat(THREE.Math.randFloat(min, max));

export const createColor = color => ({ type: 'c', value: new THREE.Color(color) });

export const createBoolean = value => ({ type: 'i', value });

export const createTexture = value => ({ type: 't', value });
