import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

import { font, colors, breakpoints } from 'src/modules/styles';
import { interpolate } from 'src/modules/animations';
import { HoverStateContext } from 'src/modules/mouse';

const S = {
  Link: styled.a`
    display: inline-block;
    word-wrap: break-word;
    ${font.size.s}
    color: ${colors.black};

    &:hover {
      color: ${colors.white};
      transform: skewX(-10deg);
      -webkit-text-stroke: 1px ${colors.black};
    }

    ${breakpoints.s`
      word-wrap: normal;
      ${font.size.m}
    `}
  `,
};

const A = {
  Link: animated(S.Link),
};

const ALink = ({
  onMouseEnter: onMouseEnterProp,
  onMouseLeave: onMouseLeaveProp,
  onMouseMove: onMouseMoveProp,
  style: styleProps,
  ...props
}) => {
  const { setIsInHoverState } = useContext(HoverStateContext);
  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = event => {
    setIsInHoverState(true);
    setIsHovered(true);
    if (onMouseEnterProp) onMouseEnterProp(event);
  };
  const onMouseLeave = event => {
    setIsInHoverState(false);
    setIsHovered(false);
    if (onMouseLeaveProp) onMouseLeaveProp(event);
  };

  const [animationStyles, setAnimationStyles] = useSpring(() => ({ x: 0 }));
  const onMouseMove = ({ clientX: x, ...rest }) => {
    if (onMouseMoveProp) onMouseMoveProp({ clientX: x, ...rest });
    if (!isHovered) return;
    const minMax = interpolate.minMax(5, 30);
    setAnimationStyles({ x: -minMax(x * 0.03) });
  };

  useEffect(() => {
    if (isHovered) return;
    setAnimationStyles({ x: 0 });
  }, [isHovered, setAnimationStyles]);

  useEffect(() => setIsInHoverState(false), [setIsInHoverState]);

  return (
    <A.Link
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      style={interpolate.skewX({ ...styleProps, ...animationStyles })}
      {...props}
    />
  );
};

ALink.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseMove: PropTypes.func,
};

ALink.defaultProps = {
  onMouseEnter: null,
  onMouseLeave: null,
  onMouseMove: null,
};

export default ALink;
