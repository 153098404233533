import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as d3 from 'd3-scale';

import { useMousePosition } from 'src/modules/mouse';

let CANVAS_WIDTH = 0;
let CANVAS_HEIGHT = 0;
let COLUMNS = 0;
let ROWS = 0;
let NUM_OF_PARTICLES = ROWS * COLUMNS;

const MAX_SIZE = 30;
const SPACING = 0;
const DRAG = 0.7;
const EASE = 0.25;
const PI2 = Math.PI * 2;

let ctx;
let ctxBg;
let particlesBg = [];
let particles = [];

const mouse = {
  active: false,
  x: 0,
  y: 0,
};

function step(calcFactor, onlyInDistance, thickness) {
  ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

  let particle;
  let distanceX;
  let distanceY;
  let distance;
  let factor;
  let tan;
  for (let i = 0; i < NUM_OF_PARTICLES; i++) {
    particle = particles[i];
    if (particle.size === 0) continue;

    if (mouse.active) {
      distanceX = mouse.x - particle.x;
      distanceY = mouse.y - particle.y;
      distance = distanceX * distanceX + distanceY * distanceY;
      factor = calcFactor(thickness, distance);

      if (!onlyInDistance || distance < thickness) {
        tan = Math.atan2(distanceY, distanceX);
        particle.vx += factor * Math.cos(tan);
        particle.vy += factor * Math.sin(tan);
      }
    }

    particle.vx *= DRAG;
    particle.vy *= DRAG;
    particle.x += particle.vx + (particle.ox - particle.x) * EASE;
    particle.y += particle.vy + (particle.oy - particle.y) * EASE;

    ctx.beginPath();
    ctx.moveTo(particle.x + particle.size + SPACING, particle.y);
    ctx.arc(particle.x, particle.y, particle.size / 2, 0, PI2, true);
    ctx.fillStyle = particle.color;
    ctx.fill();
  }

  window.requestAnimationFrame(() => step(calcFactor, onlyInDistance, thickness));
}

function stepInit(calcFactor, onlyInDistance, thickness) {
  ctxBg.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

  let particle;
  for (let i = 0; i < NUM_OF_PARTICLES; i++) {
    particle = particlesBg[i];
    if (particle.size === 0) continue;

    particle.x += (particle.ox - particle.x) * EASE;
    particle.y += (particle.oy - particle.y) * EASE;
    const radius = particle.size > 1 ? (particle.size - 1) / 2 : particle.size / 2;

    ctxBg.beginPath();
    ctxBg.moveTo(particle.x + particle.size + SPACING, particle.y);
    ctxBg.arc(particle.x, particle.y, radius, 0, PI2, true);
    ctxBg.fillStyle = particle.color;
    ctxBg.fill();
  }

  window.requestAnimationFrame(() => step(calcFactor, onlyInDistance, thickness));
}

function makeGaussian(amplitude, x0, y0, sigmaX, sigmaY) {
  return (x, y) => {
    const exponent = -(
      Math.pow(x - x0, 2) / (2 * Math.pow(sigmaX, 2)) +
      Math.pow(y - y0, 2) / (2 * Math.pow(sigmaY, 2))
    );
    return amplitude * Math.pow(Math.E, exponent);
  };
}

function createParticles() {
  particles = [];

  const intColor = d3
    .scaleLinear()
    .domain([0, COLUMNS / 4, COLUMNS / 2, (COLUMNS / 4) * 3, COLUMNS])
    .range(['#4a4a4a', '#4a4a4a', '#c3c3c3', '#4a4a4a', '#4a4a4a']);
  const gaussian = makeGaussian(MAX_SIZE, COLUMNS / 2, ROWS / 2, COLUMNS / 6, ROWS / 6);

  const sizeWithSpacing = SPACING + MAX_SIZE;
  let column;
  let row;
  let offset;
  let x;
  let y;
  let size;
  let color;
  for (let i = 0; i < NUM_OF_PARTICLES; i++) {
    column = i % COLUMNS;
    row = Math.floor(i / COLUMNS);
    offset = row % 2;

    y = sizeWithSpacing * row;
    x = sizeWithSpacing * column + (offset ? MAX_SIZE / 2 : 0);

    size = gaussian(column, row);
    color = intColor(column);

    const particle = { x, y, ox: x, oy: y, vx: 0, vy: 0, size, color };
    particles.push(particle);
  }
}

function createParticlesBg() {
  particlesBg = [];

  const intColor = d3
    .scaleLinear()
    .domain([0, COLUMNS / 4, COLUMNS / 2, (COLUMNS / 4) * 3, COLUMNS])
    .range(['#000000', '#000000', '#2e2e2e', '#000000', '#000000']);
  const gaussian = makeGaussian(MAX_SIZE, COLUMNS / 2, ROWS / 2, COLUMNS / 6, ROWS / 6);

  const sizeWithSpacing = SPACING + MAX_SIZE;
  let column;
  let row;
  let offset;
  let x;
  let y;
  let size;
  let color;
  for (let i = 0; i < NUM_OF_PARTICLES; i++) {
    column = i % COLUMNS;
    row = Math.floor(i / COLUMNS);
    offset = row % 2;

    y = sizeWithSpacing * row;
    x = sizeWithSpacing * column + (offset ? MAX_SIZE / 2 : 0);

    size = gaussian(column, row);
    color = intColor(column);

    const particle = { x, y, ox: x, oy: y, vx: 0, vy: 0, size, color };
    particlesBg.push(particle);
  }
}

function calculateVaribles() {
  CANVAS_WIDTH = window.innerWidth;
  CANVAS_HEIGHT = window.innerHeight;
  ROWS = Math.round(CANVAS_HEIGHT / (MAX_SIZE + SPACING));
  COLUMNS = Math.round(CANVAS_WIDTH / (MAX_SIZE + SPACING));
  NUM_OF_PARTICLES = ROWS * COLUMNS;
  console.log('[CANVAS|VARIABLES]', { CANVAS_WIDTH, CANVAS_HEIGHT, ROWS, COLUMNS, NUM_OF_PARTICLES });
}

function init() {
  console.log('[CANVAS|INIT]');
  calculateVaribles();
  createParticles();
  createParticlesBg();
}

const S = {
  Canvas: styled.canvas`
    position: absolute;
    top: 0;
    left: 0;
  `,
};

const Background = ({ calcFactor, onlyInDistance, thickness }) => {
  const canvasRef = useRef(null);
  const canvasBgRef = useRef(null);
  useMousePosition((x, y) => {
    mouse.active = true;
    mouse.x = x;
    mouse.y = y;
  });

  useEffect(() => {
    console.log('[CANVAS|USE_EFFECT]');
    if (!canvasRef.current) return;
    console.log('[CANVAS|HAS_REF]');
    window.onresize = () => {
      calculateVaribles();
      createParticles();
      createParticlesBg();
      canvasRef.current.width = CANVAS_WIDTH;
      canvasRef.current.height = CANVAS_HEIGHT;
      window.requestAnimationFrame(() => stepInit(calcFactor, onlyInDistance, thickness));
    };

    ctx = canvasRef.current.getContext('2d');
    ctxBg = canvasBgRef.current.getContext('2d');
    init();
    window.requestAnimationFrame(() => stepInit(calcFactor, onlyInDistance, thickness));
  }, [calcFactor, onlyInDistance, thickness]);

  return (
    <>
      <S.Canvas ref={canvasBgRef} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} />
      <S.Canvas ref={canvasRef} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} />
    </>
  );
};

Background.propTypes = {
  calcFactor: PropTypes.func.isRequired,
  onlyInDistance: PropTypes.bool.isRequired,
  thickness: PropTypes.number,
};

Background.defaultProps = {
  thickness: null,
};

export default Background;
