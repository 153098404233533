import styled from 'styled-components';

import LogoSVG from 'src/images/svgs/logo.svg';
import { margin } from 'src/modules/styles';

const Logo = styled(LogoSVG)`
  z-index: 1;
  ${margin.bottom.m}
  height: 100px;
`;

export default Logo;
