import React from 'react';
import styled from 'styled-components';

import { font, colors, breakpoints } from 'src/modules/styles';
import SkewLink from './layout.SkewLink';

const S = {
  Wrapper: styled.div`
    border-bottom: 1px solid ${colors.black};
  `,
  Link: styled(SkewLink)`
    ${font.size.xs}

    ${breakpoints.s`
    ${font.size.m}
  `}
  `,
};

const MenuItem = props => (
  <S.Wrapper>
    <S.Link {...props} />
  </S.Wrapper>
);

export default MenuItem;
