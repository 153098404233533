import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Converter } from 'showdown';

import { font, breakpoints, margin, colors } from 'src/modules/styles';

const S = {
  Div: styled.div`
  h1 {
    margin: 0;
    ${margin.bottom.l}
    ${font.size.m}
    color: ${colors.black};
    text-transform: uppercase;
    word-wrap: break-word;
  }

  h2 {
    margin: 0;
    ${margin.bottom.m}
    ${font.size.s}
    color: ${colors.black};
    text-transform: uppercase;
    word-wrap: break-word;
  }

  p {
    margin: 0;
    word-wrap: break-word;
    ${margin.bottom.m}
    ${font.size.s}
    color: ${colors.black};
  }

  img {
    max-height: 60vh;
  }

  ul li,
  ol li {
    word-wrap: break-word;
    ${font.size.s}
    color: ${colors.black};
  }

  ol {
    padding-inline-start: 50px;
  }

  a {
    word-wrap: break-word;
    color: ${colors.black};

    &:hover {
      color: ${colors.white};
      -webkit-text-stroke: 1px ${colors.black};
    }

  }

  ${breakpoints.s`
    h1 {
      ${font.size.xl}
      word-wrap: normal;
    }

    h2 {
      ${font.size.l}
      word-wrap: normal;
    }

    p {
      word-wrap: normal;
      ${font.size.m}
    }
    
    a {
      word-wrap: normal;
    }

    ul li,
    ol li {
      word-wrap: normal;
      ${font.size.m}
    }
    
    ol {
      padding-inline-start: 90px;
    }

    ul {
      padding-inline-start: 80px;
    }
  `}
  `,
};

export const HTMLContent = ({ content, className }) => (
  <S.Div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

const converter = new Converter();

const Content = ({ content, className }) => <HTMLContent className={className} content={converter.makeHtml(content)} />;

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

Content.defaultProps = {
  content: null,
  className: '',
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
