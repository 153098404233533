import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import Link from 'gatsby-plugin-transition-link';

import { font, colors } from 'src/modules/styles';
import { interpolate } from 'src/modules/animations';
import { HoverStateContext } from 'src/modules/mouse';

const S = {
  Wrapper: styled.div`
    width: 100%;

    &:hover a {
      color: ${colors.white};
      -webkit-text-stroke: 1px ${colors.black};
    }
  `,
  Link: styled(Link)`
    display: block;
    ${font.size.m}
    width: 100%;
    color: ${colors.black};
    text-decoration: none;
  `,
};

const A = {
  Wrapper: animated(S.Wrapper),
  Link: animated(S.Link),
};

const SkewLink = ({
  onMouseEnter: onMouseEnterProp,
  onMouseLeave: onMouseLeaveProp,
  onMouseMove: onMouseMoveProp,
  entry,
  exit,
  style: styleProps,
  ...props
}) => {
  const { setIsInHoverState } = useContext(HoverStateContext);
  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = event => {
    setIsInHoverState(true);
    setIsHovered(true);
    if (onMouseEnterProp) onMouseEnterProp(event);
  };
  const onMouseLeave = event => {
    setIsInHoverState(false);
    setIsHovered(false);
    if (onMouseLeaveProp) onMouseLeaveProp(event);
  };

  const [animationStyles, setAnimationStyles] = useSpring(() => ({ x: 0 }));
  const onMouseMove = ({ clientX: x, ...rest }) => {
    if (onMouseMoveProp) onMouseMoveProp({ clientX: x, ...rest });
    if (!isHovered) return;
    const minMax = interpolate.minMax(5, 30);
    setAnimationStyles({ x: -minMax(x * 0.03) });
  };

  useEffect(() => {
    if (isHovered) return;
    setAnimationStyles({ x: 0 });
  }, [isHovered, setAnimationStyles]);

  useEffect(() => setIsInHoverState(false), [setIsInHoverState]);

  const styles = ({ x, ...rest }) => ({
    ...rest,
    transform: x.interpolate(value => `skewX(${value}deg) translate3d(${-value * 0.5}px,0,0)`),
  });

  return (
    <A.Wrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      style={styles(animationStyles)}
    >
      <A.Link
        exit={{
          length: 1,
          ...exit,
        }}
        entry={{
          length: 1,
          ...entry,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        style={styleProps}
        {...props}
      />
    </A.Wrapper>
  );
};

SkewLink.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseMove: PropTypes.func,
};

SkewLink.defaultProps = {
  onMouseEnter: null,
  onMouseLeave: null,
  onMouseMove: null,
};

export default SkewLink;
